import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: "AIzaSyAR61-oGgRm8iO4AAaMl-4YdmVhdXCSL2Q",
  authDomain: "affirmationsbeta.firebaseapp.com",
  databaseURL: "https://affirmationsbeta.firebaseio.com",
  projectId: "affirmationsbeta",
  storageBucket: "affirmationsbeta.appspot.com",
  messagingSenderId: "449764684598",
  appId: "1:449764684598:web:b647deadb25a58f5efb997"
}

firebase.initializeApp(config)

